<template>
  <div>
    <d3-cloud
      :data="words"
      :font-size-mapper="fontSizeMapper"
      :rotate="rotate"
      :font="font"
      :fill="colorFunction"
    >
    </d3-cloud>
  </div>
</template>

<script>
import D3Cloud from "vue-d3-cloud";
export default {
  components: {
    D3Cloud,
  },
  props: ["words"],
  methods: {
    fontSizeMapper(word) {
      return word.size * 10; // Adjust this multiplier as needed
    },
    rotate() {
      const angle = ~~(Math.random() * 2) * 90;
      return 0;
    },
    font() {
      return "Kanit";
    },
    colorFunction(word) {
      return word.size > 10
        ? "DeepPink"
        : word.size > 5
        ? "RoyalBlue"
        : "Indigo";
    },
  },
};
</script>

<style scoped>
.d3-cloud {
  width: 100%;
  height: 500px; /* Adjust based on your layout needs */
  border: 1px solid red; /* Just to make sure you can see the container */
}
</style>
