<template>
  <div>
    <div class="row mb-2">
      <div class="col text-right">
        <b-button @click="loadData" variant="success"> Reload</b-button>
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <vue-good-table :columns="columns" :rows="filter_data" :rtl="false">
        <template slot="table-row" slot-scope="props">
          <!-- Column: time_ago -->
          <div v-if="props.column.field === 'time_ago'" class="text-nowrap">
            <div class="text-white small-badge">{{ props.row.time_ago }}</div>
          </div>

          <!-- Column: วันที่เช่า -->
          <span v-else-if="props.column.field === 'start_date'">
            <div class="row">
              <div class="col-sm-6 text-center">
                {{ props.row.start_date | day }} <br />
                <b-badge :variant="badge_variant(props.row.start_date)">
                  {{ props.row.start_date | time }}
                </b-badge>
              </div>
              <div class="col-sm-6 text-center">
                {{ props.row.end_date | day }} <br />
                <b-badge :variant="badge_variant(props.row.end_date)">
                  {{ props.row.end_date | time }}
                </b-badge>
              </div>
            </div>
          </span>

          <!-- Column: Member -->
          <span v-else-if="props.column.field === 'member'">
            <div v-if="props.row.member_id">
              <a href="" @click="viewMember(props.row.member_id)">
                {{ props.row.first_name }} {{ props.row.last_name }}
              </a>
            </div>
            <div v-if="props.row.gen_username">
              {{ props.row.gen_username }}
            </div>
            <div v-else-if="props.row.retro_username">
              {{ props.row.retro_username }}
            </div>

            <div v-if="props.row.ip_address">
              {{ props.row.ip_address }}
            </div>
          </span>

          <!-- Column: avaliable -->
          <span v-else-if="props.column.field === 'avaliable'">
            <b-badge
              variant="success"
              size="lg"
              class="text-white"
              v-if="props.row.avaliable === true"
            >
              ว่าง
            </b-badge>
            <b-badge
              variant="danger"
              size="lg"
              class="text-white"
              v-if="props.row.avaliable === false"
            >
              ไม่ว่าง
            </b-badge>
          </span>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { BBadge, BButton, BOverlay } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    BOverlay,
  },
  data() {
    return {
      columns: [],
      filter_data: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    viewMember(member_id) {
      window.open(`/member/view/${member_id}`, "_blank");
    },

    loadData() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/log/lastest`,
      })
        .then((x) => {
          const data = x.data.data;
          this.columns = data.columns;
          this.filter_data = data.filter_data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.message);
          this.loading = false;
        });
    },
    badge_variant(dateTime) {
      const time = moment(dateTime).format("HH:mm");
      if (time === "11:30" || time === "13:00") {
        return "light-info";
      } else if (time === "14:00" || time === "16:00") {
        return "light-warning";
      } else if (time === "17:00" || time === "19:00") {
        return "light-success";
      }
      // 1 info
      // 2 warning
      // 3 success
      return "light-success";
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
