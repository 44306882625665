<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h1>search log bar</h1>
          <date-picker-range @date_picked="date_picked"></date-picker-range>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <b-button
            v-if="!loading"
            variant="relief-success"
            @click="loadData()"
          >
            Search
          </b-button>
          <b-button
            variant="relief-success"
            disabled
            class="mr-1"
            v-if="loading"
          >
            <b-spinner small /> &nbsp; Loading...
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import DatePickerRange from "../../account/component/DatepickerRange.vue";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    DatePickerRange,
  },
  created() {
    // this.loadData();
  },
  data() {
    return {
      form: {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
      },
      loading: false,
    };
  },
  methods: {
    date_picked(x) {
      this.form.date = x;
      this.loadData();
    },
    loadData() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/log/search`,
        data: this.form,
      })
        .then((x) => {
          const data = x.data.data;
          this.loading = false;
          this.$emit("search_text_data", data);
          this.$emit("loading", false);
        })
        .catch((e) => {
          console.log(e.message);
          this.$emit("loading", false);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
