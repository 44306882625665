<template>
  <div>
    <h1>Log Analytic</h1>

    <filter-stat-bar
      v-if="activeTabIndex === 0"
      @filter_data="filter_data"
      @loading="handleLoading"
    >
    </filter-stat-bar>

    <search-log-bar
      v-if="activeTabIndex === 2"
      @search_text_data="search_text_data"
    >
    </search-log-bar>
    <b-tabs v-model="activeTabIndex">
      <b-tab title="อันดับเช็คคิว">
        <b-card bg-variant="light">
          <b-overlay :show="loading" rounded="sm">
            <world-cloud :words="words"></world-cloud>
          </b-overlay>
        </b-card>
      </b-tab>
      <b-tab title="เช็คคิวล่าสุด">
        <b-card>
          <lastest-ticker :columns="[]" :filter_data="[]"> </lastest-ticker>
        </b-card>
      </b-tab>
      <b-tab title="Search Text">
        <b-card bg-variant="light">
          <world-cloud :words="search_text_words"></world-cloud>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BOverlay, BTabs, BTab } from "bootstrap-vue";
import WorldCloud from "./component/WordCloud.vue";
import FilterStatBar from "./component/FilterStatBar.vue";
import LastestTicker from "./component/LastestTicker.vue";
import SearchLogBar from "./component/SearchLogBar.vue";
export default {
  components: {
    BOverlay,
    BCard,
    WorldCloud,
    FilterStatBar,
    LastestTicker,
    SearchLogBar,
    BTabs,
    BTab,
  },
  data() {
    return {
      search_text_words: [],
      words: [],
      loading: false,
      activeTabIndex: 0,
    };
  },

  methods: {
    filter_data(data) {
      this.words = data;
    },
    handleLoading(value) {
      this.loading = value;
    },
    search_text_data(data) {
      this.search_text_words = data;
    },
  },
};
</script>
