<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-md-8">
          <!-- Date Mode -->
          <b-form-group label="Date Mode" class="mb-2">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.date_mode"
                name="some-radios3"
                value="check_date"
                class="mt-0"
              >
                วันที่เช็คคิว
              </b-form-radio>
              <b-form-radio
                v-model="form.date_mode"
                name="some-radios3"
                value="pickup_date"
                class="mt-0"
              >
                วันที่รับ
              </b-form-radio>
            </div>
          </b-form-group>
          <!-- ว่าง / ไม่ว่าง -->
          <b-form-group label="ว่าง / ไม่ว่าง">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.avaliable"
                name="some-radios2"
                value="all"
                class="mt-0"
              >
                ทั้งหมด
              </b-form-radio>
              <b-form-radio
                v-model="form.avaliable"
                name="some-radios2"
                value="true"
                class="mt-0"
              >
                อุปกรณ์ว่าง
              </b-form-radio>
              <b-form-radio
                v-model="form.avaliable"
                name="some-radios2"
                value="false"
                class="mt-0"
              >
                อุปกรณ์ไม่ว่าง
              </b-form-radio>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-4">
          <date-picker-range @date_picked="date_picked"></date-picker-range>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col text-right">
          <b-button
            variant="relief-success"
            class="btn-icon"
            @click="loadData()"
          >
            Search
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BFormGroup, BFormRadio } from "bootstrap-vue";
import DatePickerRange from "../../account/component/DatepickerRange.vue";
import moment from "moment";

export default {
  components: {
    BCard,
    BButton,
    DatePickerRange,
    BFormGroup,
    BFormRadio,
  },
  data() {
    return {
      form: {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        date_mode: "check_date", /// check_date , pickup_date
        avaliable: "all",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    date_picked(x) {
      this.form.date = x;
      this.loadData();
    },
    loadData() {
      this.loading = true;
      this.$emit("loading", true);
      this.$http({
        method: "POST",
        url: `/log/stat/filter`,
        data: this.form,
      })
        .then((x) => {
          const data = x.data.data;
          this.loading = false;
          this.$emit("filter_data", data);
          this.$emit("loading", false);
        })
        .catch((e) => {
          console.log(e.message);
          this.$emit("loading", false);
          this.loading = false;
        });
    },
  },
};
</script>
